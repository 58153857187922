import { createStore } from 'vuex'
import getters from './getter'
// import { getMenu } from '../plugin/api'
const state = {
  menuList: []
}
const mutations = {
  SET_MENU_LIST: (state, list) => {
    state.menuList = list
  }
}

const actions = {
  // async GenerateRoutes({ commit }, params) {
  //   return new Promise(resolve => {
  //     getMenu(params).then(res => {
  //       if (res.data.code === 200) {
  //         const list = res.data.data.menuList
  //         commit('SET_MENU_LIST', list)
  //         resolve(res)
  //       }
  //     })
  //   })
  // }
}

export default createStore({
  // module: {
  //   permission
  // },
  state,
  mutations,
  actions,
  getters
})
