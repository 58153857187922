<template>
  <div class="footer">
    <div class="content">
      <div class="left">
        <div class="word_list">
          <p class="title">监管在线</p>
          <ul>
            <li v-for="(item,index) in regularList" :key="index">
              <a target="_blank" :href="item.url">{{ item.title }}</a>
            </li>
          </ul>
        </div>
        <div class="word_list">
          <p class="title">友情链接</p>
          <ul>
            <li v-for="(item,index) in friendshipList" :key="index">
              <a target="_blank" :href="item.url">{{ item.title }}</a>
            </li>
          </ul>
        </div>
        <div class="word_list">
          <p class="title">快捷入口</p>
          <ul>
            <li v-for="(item,index) in quickList" :key="index">
              <a target="_blank" :href="item.url">{{ item.title }}</a>
            </li>
          </ul>
        </div>
        <div class="filings">
          <div class="pub_net">
            <img :src="baseUrl + info.iconUrl" alt="">
            <p>{{ info.shortTitle || '' }}</p>
          </div>
          <p>{{ info.remark || '' }}</p>
        </div>
      </div>
      <div class="right">
        <div v-for="(item,index) in menuList" :key="index" class="menu">
          <p class="title" @click="handleSelect(item)">{{ item.name }}</p>
          <ul>
            <li v-for="(cItem,cIndex) in item.children" :key="cIndex" @click="handleSelect(item,cItem)">{{ cItem.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenu } from '@/plugin/api'

export default {
  data() {
    return {
      info: {
        shortTitle: '',
        remark: '',
        iconUrl: ''
      },
      menuList: [],
      regularList: [],
      friendshipList: [],
      quickList: [],
      baseUrl: '/website'
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    handleSelect(item, cItem) {
      let rPath = ''
      let rQuery = {
        urlType: '',
        url: ''
      }
      rPath = item.path !== '/xljrHoldingCompany' ? item.path : cItem ? '/xljrDetailCompany' : item.path
      rQuery = item.path !== '/xljrHoldingCompany'
        ? item.path === '/' ? {} : cItem ? { urlType: cItem.urlType, url: cItem.url, fUrlType: item.urlType, fUrl: item.url } : { urlType: item.urlType, url: item.url }
        : cItem ? { urlType: cItem.urlType, url: cItem.url, fUrlType: item.urlType, fUrl: item.url } : { urlType: item.urlType, url: item.url }
      this.$router.push({ path: rPath, query: rQuery })
    },
    getInfo() {
      getMenu({ tenantId: 'xljr' }).then(res => {
        const arr = res.data.data.menuList
        arr.shift()
        arr.pop()
        this.menuList = arr
        const cArr = res.data.data.contentMainList ? res.data.data.contentMainList : []
        this.regularList = cArr.find(item => {
          return item.id === 137
        }).contentItemTreeList
        this.friendshipList = cArr.find(item => {
          return item.id === 150
        }).contentItemTreeList
        this.quickList = cArr.find(item => {
          return item.id === 138
        }).contentItemTreeList

        this.info = cArr.find(item => {
          return item.id === 139
        })
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.footer{
  background-image: url(../assets/images/home/footer_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 0 50px 0;
  display: inline-table!important;
  width: 100%!important;
  .content{
    display: flex;
    justify-content: space-around;
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    .left{
      width: 42%;
      .word_list{
        margin-bottom: 15px;
        .title{
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          li{
            padding: 0 10px;
            font-size: 14px;
            margin-bottom: 10px;
            &:first-child{
              padding-left: 0;
            }
            a{
              color: #fff;
            }
          }
        }
      }
      .filings{
        font-size: 14px;
        .pub_net{
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;
          img{
            margin-right: 10px;
          }
        }
      }
    }
    .right{
      width: 58%;
      display: flex;
      justify-content: center;
      .menu{
        &:last-child{
          .title{
            border: none;
          }
        }
        .title{
          font-weight: 700;
          cursor: pointer;
          padding: 0 30px;
          font-size: 18px;
          border-right: 2px solid #fff;
          margin-bottom: 20px;
        }
        ul{
          padding: 0 30px;
          li{
            cursor: pointer;
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

</style>
