import { createRouter, createWebHashHistory } from 'vue-router'

const routerHistory = createWebHashHistory() // 引入路由模式 History

const indexRouter = {
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/homePage.vue')
    },
    // 走进新力
    {
      path: '/xljrEnteringXinLi',
      name: 'xljrEnteringXinLi',
      component: () => import('../views/companyIntroduction.vue')
    },
    // 控股企业
    {
      path: '/xljrHoldingCompany',
      name: 'xljrHoldingCompany',
      component: () => import('../views/holdingCompany.vue')
    },
    {
      path: '/xljrDetailCompany',
      name: 'xljrDetailCompany',
      component: () => import('../views/detailCompany.vue')
    },
    // 投资者关系
    {
      path: '/xljrInvestorRelations',
      name: 'xljrInvestorRelations',
      component: () => import('../views/investorRelations.vue')
    },
    {
      path: '/xljrProductServices',
      name: 'xljrProductService',
      component: () => import('../views/productService.vue')
    },
    {
      path: '/xljrContactUs',
      name: 'xljrContactUs',
      component: () => import('../views/contactUs.vue')
    },
    {
      path: '/archieveDetail',
      name: 'archieveDetail',
      component: () => import('../views/archieveDetail.vue')
    },
    {
      path: '/enterpriseBuilding',
      name: 'enterpriseBuilding',
      component: () => import('../views/enterpriseBuilding.vue')
    }
  ]
}
const route = createRouter(indexRouter)
export default route
