import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from '../src/router/index'
import store from './store/index'

// ElementPlus引入
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import zhCn from 'element-plus/es/locale/lang/zh-cn'

import 'animate.css'
import '@/assets/css/index.scss'
import '@/assets/css/quill.bubble.css'
import '@/assets/css/quill.core.css'
import '@/assets/css/quill.snow.css'

// import 'default-passive-events'
// 页面监控埋点
// import '../src/plugin/buryingpoint'
const app = createApp(App)
app
  .use(ElementPlus, {
    locale: zhCn
  })
  .use(store)
  .use(router)
  .mount('#app')
app.config.globalProperties.$axios = axios
