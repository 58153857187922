<template>
  <div class="header">
    <div class="content">
      <img class="logo" src="../../statics/images/home/xljr_logo.png" alt="">
      <div class="nav">
        <el-menu
          :default-active="activeIndex"
          class="head_menu"
          mode="horizontal"
          :ellipsis="false"
        >
          <el-menu-item
            v-for="(item,index) in headerList"
            :key="index+''"
            :index="item.path"
          >
            <a @click="handleSelect(item)">{{ item.name }}</a>
            <div class="menu_block" />
            <ul class="menu_c">
              <li v-for="(cItem,cIndex) in item.children" :key="cIndex" @click="handleSelect(item,cItem)">{{ cItem.name }}</li>
            </ul>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      activeIndex: '/'
    }
  },
  watch: {
    $route(to, from) {
      const fPath = to.fullPath.split('?')[0]
      const pathStr = fPath.split('/')[1]
      this.activeIndex = fPath === '/' ? fPath : '/' + pathStr
    }
  },
  methods: {
    handleSelect(item, cItem) {
      let rPath = ''
      let rQuery = {
        urlType: '',
        url: ''
      }
      rPath = item.path !== '/xljrHoldingCompany' ? item.path : cItem ? '/xljrDetailCompany' : item.path
      rQuery = item.path !== '/xljrHoldingCompany'
        ? item.path === '/' ? {} : cItem ? { urlType: cItem.urlType, url: cItem.url, fUrlType: item.urlType, fUrl: item.url } : { urlType: item.urlType, url: item.url }
        : cItem ? { urlType: cItem.urlType, url: cItem.url, fUrlType: item.urlType, fUrl: item.url } : { urlType: item.urlType, url: item.url }
      this.$router.push({ path: rPath, query: rQuery })
    },
    toBottom() {
      window.scrollTo({
        top: 100000,
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header{
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 9;
  box-shadow: 0px 2px 3px 0px rgba(183, 182, 189, 50);
  .content{
    width: 1400px;
    min-width: 1280px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    .logo{
      width: 160px;
    }
    .nav{
      display: flex;
      align-items: center;
      position: relative;
      .head_menu{
        align-items: center;
      }
      .contact{
        font-size: 18px;
        padding: 0 30px;
        line-height: 50px;
        cursor: pointer;
      }
    }
  }
}
// 屏幕大于 1024px 或小于 1440px 时应用该样式
@media screen and (max-width: 1440px) {
  .header{
    .content{
      width: 1200px;
    }
  }
}
.header .content .nav .head_menu{
    height: 80px;
}
.el-menu--horizontal{
  border: none;
}
.el-menu-item{
  font-size: 18px;
  width: 132px;
  line-height: 50px;
}
.el-menu--horizontal>.el-menu-item{
  border-bottom: unset;
  position: relative;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: #fff;
  color: #155FAE!important;
}
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: unset;
    color: #155FAE!important;
}
.el-menu--horizontal>.el-menu-item.is-active .menu_block{
  visibility: visible;
  height: 4px;
}
.el-menu--horizontal>.el-menu-item:hover .menu_block{
  border-bottom: unset;
  color: #155FAE!important;
  visibility: visible;
  height: 4px;
}
.menu_block{
  transition: all .3s;
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: #155FAE;
}
.el-menu--horizontal>.el-menu-item:hover .menu_c{
  visibility: visible;
}
.menu_c{
  color: #000;
  visibility: hidden;
  position: absolute;
  top: 80px;
  left: 0;
  opacity: 0.8;
  background-color: #fff;
  border-radius: 5px;
  li{
    width: 135px;
    text-align: center;
    &:hover{
      color: #155FAE;
    }
  }
}
</style>
