import axios from 'axios'
// import { ElMessageBox } from 'element-plus'
// const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : window.baseURL
const config = {
  baseURL: '/website', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    'Content-Type': 'application/json;charset=utf-8 '
  }
}
const request = axios.create(config)

export default request

export function post(service, api, data) {
  return request({
    url: '/ajax/partnerReq',
    method: 'post',
    data:
      { service, api, params: JSON.stringify(data) }
  })
}

// 响应拦截器
request.interceptors.response.use(res => {
  return res
},
error => {
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }

  return Promise.reject({ data: { resultCode: 1000, errorMessage: message }})
}
)
