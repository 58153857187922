<template>
  <div>
    <Header :header-list="headerList" />
    <router-view class="router_content" />
    <Footer />
  </div>
</template>

<script>
import Header from './components/header'
import Footer from './components/footer'
import { getMenu } from '@/plugin/api'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      headerList: [],
      footerList: [],
      footerObj: {
        friUrl: [],
        contactList: [],
        codeUrl: ''
      }
    }
  },
  mounted() {
    document.body.style.setProperty('--el-color-primary', '#155FAE')
    document.body.style.setProperty('--el-menu-hover-text-color', '#155FAEY')
    this.getWebInfo()
  },
  methods: {
    getWebInfo() {
      getMenu({ tenantId: 'xljr' }).then(res => {
        this.headerList = res.data.data.menuList
      })
    }
  }
}
</script>
