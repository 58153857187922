import request from '@/plugin/request'

export function getMenu(query) {
  return request({
    url: '/api/webSite/getMenuListByTenantId',
    method: 'get',
    params: query
  })
}

export function getContentList(query) {
  return request({
    url: '/api/webSite/getContentListByMenuId',
    method: 'get',
    params: query
  })
}

export function getMainContent(query) {
  return request({
    url: '/api/webSite/getContentItemListByMainId',
    method: 'get',
    params: query
  })
}

export function getArchiveListByMenuId(query) {
  return request({
    url: '/api/webSite/getArchiveListByMenuId',
    method: 'get',
    params: query
  })
}

export function addVisitorRecord(data) {
  return request({
    url: '/api/webSite/addVisitorRecord',
    method: 'post',
    data
  })
}

export function queryAdvert(query) {
  return request({
    url: '/api/webSite/queryAdvert',
    method: 'get',
    params: query
  })
}

export function stockPrice(query) {
  return request({
    url: 'https://qt.gtimg.cn/q=sh600318',
    method: 'get',
    params: query
  })
}
